import React from 'react'
import { useOverlayStore } from '../stores/overlay'



const PopupLink = ({ linkLabel, popup, classes }) => {
  const {
    setOverlayDisplay,
    setContentType,
    setTitle,
    setBody,
  } = useOverlayStore()

  const extraClasses = classes || 'text-base'

  const handleClick = () => {
    setOverlayDisplay(true)
    setContentType('popup')
    setTitle(popup?.title)
    setBody(popup?.body)
  }

  return (
    <button
      type="button"
      className={`${extraClasses} text-left transition transition-colors duration-300 text-blue-500 hover:text-blue-400 font-bold`}
      onClick={handleClick}
    >
      {linkLabel}
    </button>
  )
}

export default PopupLink
